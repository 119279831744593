import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { AvailableVersions, IEnvironment } from '../interfaces';
import { trackEvent } from '../services';
import Api from '../services/api';
import { StorageKey, View } from '../types';

const { parameters } = window.globalConfiguration;

const LATEST_VERSION = '5.35.0';
const BOOTSTRAP_V4_VERSION = '4.39.0';
interface EnvironmentContextProps {
  environment: IEnvironment;
  changeEnvironment: (env: IEnvironment) => void;
  changeLayout: (arg: View) => void;
  availableVersions: AvailableVersions;
  latestVersion: string;
  latestV4: string;
}

const DEFAULT_ENVIRONMENT: IEnvironment = {
  // version: parameters.sgbs_version,
  breakpoint: parameters.breakpoint,
  view: (localStorage.getItem(StorageKey.EDITOR_VIEW) as View) || parameters.view,
  preview: true,
};

const EnvironmentContext = createContext<EnvironmentContextProps>({
  environment: { ...DEFAULT_ENVIRONMENT },
  changeEnvironment: () => {
    /* */
  },
  changeLayout: () => {
    /* */
  },
  availableVersions: {
    versions: [],
    branches: [],
  },
  latestVersion: LATEST_VERSION,
  latestV4: BOOTSTRAP_V4_VERSION,
});

const EnvironmentProvider = (props: PropsWithChildren) => {
  const [environment, setEnvironment] = useState(DEFAULT_ENVIRONMENT);
  const [availableVersions, setAvailableVersions] = useState<AvailableVersions>({
    versions: [],
    branches: [],
  });
  const [latestVersion, setLatestVersion] = useState(LATEST_VERSION);
  const [latestV4, setLatestV4] = useState(BOOTSTRAP_V4_VERSION);

  const changeLayout = useCallback(
    (layout: View) => {
      localStorage.setItem(StorageKey.EDITOR_VIEW, layout);
      trackEvent('View', layout);
      setEnvironment({ ...environment, view: layout });
    },
    [environment]
  );

  useEffect(() => {
    Api.getAvailableVersions().then((data) => {
      setAvailableVersions(data);
      setLatestVersion(data.versions[0] || LATEST_VERSION);
      setLatestV4(data.versions.find((v: string) => v.startsWith('4.')) || BOOTSTRAP_V4_VERSION);
    });
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        changeEnvironment: setEnvironment,
        changeLayout,
        availableVersions,
        latestVersion,
        latestV4,
      }}
    >
      {props.children}
    </EnvironmentContext.Provider>
  );
};

export function useEnvironment() {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error('useEnvironment must be used within a EnvironmentProvider');
  }
  return context;
}

export { EnvironmentContext, EnvironmentProvider };
