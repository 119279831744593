import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { EMPTY_METADATA, EMPTY_PLAYGROUND, playgroundRights } from '../components/common';
import { EditorType } from '../components/Editor/CodeEditor/CodeEditor';
import { IPlayground, ITeamMember } from '../interfaces';
import { EditorMetadata, PlaygroundMetadata } from '../interfaces/metadata';
import { generatePGName, getUserSub, isSGUser } from '../services';
import Api from '../services/api';
import { captureAndSave } from '../services/capture';
import { PlaygroundRight, PRIVACY_KEYS, StorageKey } from '../types';
import { useEnvironment } from './EnvironmentContext';

const noop = () => { };

interface PlaygroundContextProps {
  playground: IPlayground;

  savePlayground: () => Promise<IPlayground>;
  duplicatePlayground: () => Promise<IPlayground>;
  getPlayground: (id: string) => Promise<IPlayground>;
  initPlayground: (code?: string | null, sgbsVersion?: string | null) => void;

  changeName: (name: string) => void;
  changeDescription: (description: string) => void;
  changeCode: (code: string) => void;
  changeSGBSVersion: (version: string) => void;
  changeTheme: (isDark: boolean) => void;
  changePrivacy: (privacy: PRIVACY_KEYS) => void;
  addUser: (user: string, right: PlaygroundRight, contactId?: string) => void;
  updateUser: (user: string, right: PlaygroundRight, contactId?: string) => void;
  removeUser: (user: string) => void;
  changeExposition: (exposed: boolean) => void;
  changeTemplate: (isTemplate: boolean) => void;
  setAutoSave: (autoSave: boolean) => void;

  metadata: PlaygroundMetadata;
  updateMetadata: (editor: EditorType, meta: EditorMetadata) => void;

  isOwner: () => boolean;
  isDirty: () => boolean;
}

export const PlaygroundContext = createContext<PlaygroundContextProps>({
  playground: EMPTY_PLAYGROUND,
  savePlayground: () => new Promise((resolve) => resolve(EMPTY_PLAYGROUND)),
  duplicatePlayground: () => new Promise((resolve) => resolve(EMPTY_PLAYGROUND)),
  getPlayground: (_id: string) => new Promise((resolve) => resolve(EMPTY_PLAYGROUND)),
  isOwner: () => true,
  isDirty: () => false,
  initPlayground: noop,
  changeName: noop,
  changeDescription: noop,
  changeCode: noop,
  changeSGBSVersion: noop,
  changeTheme: noop,
  changePrivacy: noop,
  addUser: noop,
  updateUser: noop,
  removeUser: noop,
  changeExposition: noop,
  changeTemplate: noop,
  setAutoSave: noop,
  metadata: EMPTY_METADATA,
  updateMetadata: noop,
});

export const PlaygroundProvider = ({ children }: { children: ReactNode }) => {
  const [playground, setPlayground] = useState<IPlayground>(EMPTY_PLAYGROUND);
  const [metadata, setMetadata] = useState<PlaygroundMetadata>(EMPTY_METADATA);
  const { latestVersion } = useEnvironment();

  const [dirty, setDirty] = useState<boolean>(false);
  // TODO Manage autosave:
  //   - when changing name;
  //   - adding / remove / updating users;
  //   - when changing privacy;
  //   - changing code (add a threshold to avoid too many requests)!!
  //   - what about history (we do not want to keep the history of all changes!)?
  // TODO: should we use PATCH instead of PUT?
  const [autoSave, setAutoSave] = useState<boolean>(false);

  if (autoSave) {
    console.log('Autosave...');
  }

  const getPlayground = useCallback(async (id: string): Promise<IPlayground> => {
    setDirty(false);
    if (!id) {
      throw new Error('Cannot retrieve a Playground without its ID');
    }
    return Api.get(id).then((pg: IPlayground) => {
      if (!pg.metadata) {
        pg.metadata = EMPTY_METADATA;
      }
      setPlayground(pg);
      setMetadata(pg.metadata);
      return pg;
    });
  }, []);

  const savePlayground = useCallback(async (): Promise<IPlayground> => {
    const pgToSave = {
      ...playground,
      name: playground.name || generatePGName(),
      metadata,
    };

    if (!pgToSave.shortId) {
      return Api.save(pgToSave).then(async (pg: IPlayground) => {
        setDirty(false);
        setPlayground(pg);
        setMetadata(pg.metadata || EMPTY_METADATA);
        await captureAndSave(pg);
        return pg;
      });
    }
    return Api.update(pgToSave).then(async (pg: IPlayground) => {
      setDirty(false);
      setPlayground(pg);
      setMetadata(pg.metadata || EMPTY_METADATA);
      await captureAndSave(pg);
      return pg;
    });
  }, [playground, metadata]);

  const duplicatePlayground = useCallback(async (): Promise<IPlayground> => {
    // TODO Should we save automatically or just create a fork "locally" that need to be saved?
    const newOwner = getUserSub();
    const duplicate: Partial<IPlayground & { id: string }> = {
      ...playground,
      team: [],
      owner: newOwner,
      lastUpdate: null,
    };
    delete duplicate.shortId;
    delete duplicate.id;
    return Api.save(duplicate as IPlayground).then((pg: IPlayground) => {
      setDirty(false);
      setPlayground(pg);
      setMetadata(pg.metadata || EMPTY_METADATA);
      return pg;
    });
  }, [playground]);

  const initPlayground = useCallback(
    (code?: string | null, sgbsVersion?: string | null) => {
      const dark = localStorage.getItem(StorageKey.EDITOR_THEME) === 'dark';
      const pg: IPlayground = {
        ...EMPTY_PLAYGROUND,
        code: code || '',
        privacy: isSGUser() ? 'public' : 'private',
        owner: getUserSub(),
        dark,
        sgbsVersion: sgbsVersion || latestVersion,
      };
      setPlayground(pg);
      setMetadata(pg.metadata || EMPTY_METADATA);
      setDirty(false);
    },
    [latestVersion]
  );

  const changeCode = useCallback((code: string) => {
    setDirty(true);
    setPlayground((prevPlayground) => ({ ...prevPlayground, code }));
  }, []);

  const addUser = useCallback(
    (user: string, right: PlaygroundRight, contactId?: string) => {
      setDirty(true);
      setPlayground((prevPlayground) => ({
        ...prevPlayground,
        team: playground.team.concat([
          {
            mail: user,
            right,
            contactId: contactId || null,
          },
        ]),
      }));
    },
    [playground]
  );

  const changeTheme = useCallback((isDark: boolean) => {
    setDirty(true);
    setPlayground((prevPlayground) => ({ ...prevPlayground, dark: isDark }));
    localStorage.setItem(StorageKey.EDITOR_THEME, isDark ? 'dark' : 'standard');
  }, []);

  const changeSGBSVersion = useCallback((version: string) => {
    setDirty(true);
    setPlayground((prevPlayground) => ({ ...prevPlayground, sgbsVersion: version }));
  }, []);

  const removeUser = useCallback(
    (user: string) => {
      setDirty(true);
      setPlayground((prevPlayground) => ({
        ...prevPlayground,
        team: playground.team.filter((member: ITeamMember) => member.mail !== user),
      }));
    },
    [playground]
  );

  const updateUser = useCallback(
    (user: string, right: PlaygroundRight, contactId?: string) => {
      // Remove the user from list
      const team = playground.team.filter((t: ITeamMember) => t.mail !== user);
      team.push({
        mail: user,
        right,
        contactId: contactId || null,
      });
      setDirty(true);
      setPlayground((prevPlayground) => ({ ...prevPlayground, team }));
    },
    [playground]
  );

  const changeName = useCallback(
    (name: string) => {
      if (name !== playground.name) {
        setDirty(true);
        setPlayground((prevPlayground) => ({ ...prevPlayground, name }));
      }
    },
    [playground]
  );

  const changeDescription = useCallback(
    (description: string) => {
      if (description !== playground.description) {
        setDirty(true);
        setPlayground((prevPlayground) => ({ ...prevPlayground, description }));
      }
    },
    [playground]
  );

  const isOwner = useCallback(() => !playground.shortId || playgroundRights(playground) === PlaygroundRight.Owner, [
    playground,
  ]);

  const changePrivacy = useCallback((privacy: PRIVACY_KEYS) => {
    setDirty(true);
    setPlayground((prevPlayground) => ({ ...prevPlayground, privacy }));
  }, []);

  const changeExposition = useCallback((exposed: boolean) => {
    setDirty(true);
    setPlayground((prevPlayground) => ({ ...prevPlayground, exposed }));
  }, []);

  const changeTemplate = useCallback((isTemplate: boolean) => {
    setDirty(true);
    setPlayground((prevPlayground) => ({ ...prevPlayground, template: isTemplate }));
  }, []);

  const isDirty = useCallback(() => dirty, [dirty]);

  const updateMetadata = useCallback(
    (editor: EditorType, meta: EditorMetadata) => {
      const mergedMetadata: PlaygroundMetadata = playground.metadata
        ? {
          ...playground.metadata,
          editors: {
            ...playground.metadata.editors,
            [editor]: { ...meta },
          },
        }
        : {
          editors: {
            [editor]: { ...meta },
          },
        };

      setDirty(true);
      setMetadata(mergedMetadata);
    },
    [playground]
  );

  const playgroundContext = useMemo(
    () => ({
      playground,
      savePlayground,
      duplicatePlayground,
      getPlayground,
      initPlayground,
      changeName,
      changeDescription,
      changePrivacy,
      changeCode,
      changeSGBSVersion,
      changeTheme,
      addUser,
      updateUser,
      removeUser,
      isOwner,
      isDirty,
      setAutoSave,
      changeExposition,
      changeTemplate,
      metadata,
      updateMetadata,
    }),
    [
      playground,
      savePlayground,
      duplicatePlayground,
      getPlayground,
      initPlayground,
      changeName,
      changeDescription,
      changePrivacy,
      changeCode,
      changeSGBSVersion,
      changeTheme,
      addUser,
      updateUser,
      removeUser,
      isOwner,
      isDirty,
      setAutoSave,
      changeExposition,
      changeTemplate,
      metadata,
      updateMetadata,
    ]
  );

  return <PlaygroundContext.Provider value={playgroundContext}>{children}</PlaygroundContext.Provider>;
};
